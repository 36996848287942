.card-total {
    display: flex;
    flex-direction: column;   /* Stacks items vertically */
    align-items: flex-start;  /* Align items to the start (left) */
    justify-content: flex-start;  /* Align items to the top */
    text-align: left;         /* Align text to the left */
    height: 100vh;            /* Ensures the container takes up full viewport height */
    margin-left: 0;           /* Ensure no left margin */
    padding: 0;               /* Remove padding to avoid extra space */
}

.card-image {
    width: 250px;             /* Set a width for the image */
    height: 250px;            /* Set the height equal to the width */
    border-radius: 50%;       /* This makes the image a circle */
    object-fit: cover;        /* Ensures the image covers the circle without distortion */
}

.card-title {
    color: white;
    font-size: 32px;
    margin-bottom: 5px;
    white-space: normal;     /* Allows the text to wrap onto the next line */
    word-wrap: break-word;   /* Ensures long words will break if needed */
    text-align: center;      /* Center-align the text */
    width: 100%;             /* Make the title take full width of the card */
    max-width: 250px;        /* Set a maximum width to enforce wrapping */
}


.card-title a {
    color: inherit;           /* Inherit the color from the parent (white) */
    text-decoration: none;    /* Remove underline */
}

.card-title a:hover {
    text-decoration: none;    /* Prevent underline on hover */
}

.card-description {
    text-align: center;
    color: whitesmoke;
    font-size: 18px;
    margin-top: 0;            /* Remove top margin */
}

