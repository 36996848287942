html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden; // Prevent horizontal scrolling
}

.container {
    width: 100%; // Ensure the container is full width
    height: 100vh; // Use viewport height to cover the full height of the screen
    margin: 0 auto; // Center the container
    padding: 0; // No padding
    box-sizing: border-box; // Include padding and borders in the element's size
}

.home-page {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; // Optional: Fixed background
    height: 100vh; // Full viewport height
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;

    // Pseudo-element for tint
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75); // Black tint with 50% opacity
        z-index: -1; // Places the tint behind the content
    }
    background-size: cover; // This will cover the entire area of the container
    background-position: center; // This centers the background image
    background-repeat: no-repeat;

    .text-zone {
        position: absolute;
        left: 10%;
        top: 47%;
        transform: translateY(-50%);
        width: 600%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 70px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        margin-left: -21px;

        &::before {
            content: '';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 1px;
            opacity: 0.6;
        }

        &::after {
            content: '';
            font-family: 'la Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 41px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 20px;
        font-family: sans-serif;
        letter-spacing: 2px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }
}