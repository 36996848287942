.calendly-widget-container {
  flex: 1; // Takes up half the space of the contact form
  margin: 0 10px; // Add margin for spacing
  min-width: 160px; // Adjust the minimum width if necessary to ensure usability
}

@media (max-width: 768px) {
  .calendly-widget-container {
    margin: 10px 0; // Adjust margin for vertical layout
    width: auto; // Ensure full width on small screens
  }
}

.total-container {
  display: flex;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  height: 100vh; /* Full viewport height (optional) */
}