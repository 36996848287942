.program-alumni-header {
    margin-left: 120px;
    margin-top: 40px;
    font-size: 60px;
    color: white;
}

.cohort-1-header {
    margin-left: 120px;
    font-size: 35px;
    color: rgb(197, 188, 188);
}

.second {
    margin-top: -300px;
}

.third {
    margin-top: -300px;
}

.cohort-2-header {
    margin-left: 120px;
    font-size: 35px;
    color: rgb(197, 188, 188);
    margin-top: -300px;
}

.fourth {
    margin-top: 50px;
}

.fifth {
    margin-top: -300px;
}

.cohort-3-header {
    margin-left: 120px;
    font-size: 35px;
    color: rgb(197, 188, 188);
    margin-top: -300px;
}

.sixth {
    margin-top: 50px;
}

.seventh {
    margin-top: -300px;
}